import React, { useContext, useEffect } from 'react';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { Layout, Seo } from 'containers';
import { useMetaDataQuery, useStoryblok } from 'hooks';
import { isAnySocialLink, setAuthorSocialIcons } from 'utilities';
import { NavigationContext } from 'context/navigationContext';
import { author, avatar, socials } from 'styles/templates/Author.module.css';

const Author = ({ pageContext, location }) => {
	const { siteUrl } = useMetaDataQuery();
	const mainNavigation = pageContext.mainNavigation.items;
	const { setNavItems } = useContext(NavigationContext);
	const story = useStoryblok(pageContext.story, location);
	const {
		description,
		first_name,
		last_name,
		linkedin,
		meta_description,
		meta_title,
		photoLarge,
		prefix,
		suffix,
		twitter,
		university_name,
		university_url,
		url,
	} = story.content;
	const pathToImage = getImage(photoLarge?.node);
	const img = getSrc(photoLarge) ?? '';
	const socialLinks = [university_url, twitter, linkedin];
	const fullName = `${first_name} ${last_name}`;
	const liveUrl = `${siteUrl}/${url}`;

	const schema = {
		name: fullName,
		description,
		url: liveUrl,
		twitter,
		linkedin,
		image: `${siteUrl}${img}`,
		suffix,
		prefix,
		university_name,
		university_url,
	};

	useEffect(() => {
		setNavItems(mainNavigation);
	}, []);

	return (
		<Layout type='author'>
			<Seo
				isAuthorPage
				title={meta_title}
				description={meta_description}
				url={liveUrl}
				image={`${siteUrl}${img}`}
				schema={schema}
			/>
			<section className={author}>
				<GatsbyImage image={pathToImage} className={avatar} alt={fullName} />
				<h1 className='txt-center mt-2'>{`${!!prefix ? `${prefix} ` : ''}${fullName}${
					!!suffix ? ` ${suffix}` : ''
				}`}</h1>
				{isAnySocialLink(socialLinks) && (
					<div>{setAuthorSocialIcons(university_url, university_name, twitter, linkedin, socials)}</div>
				)}
				<p className='txt-center'>{description}</p>
			</section>
		</Layout>
	);
};

export default Author;
